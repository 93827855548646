<template>
  <AuthWrapper>
    <!-- <p class="auth-notice">Don&rsquo;t have an account? <router-link to="/auth/register">Sign up now</router-link></p> -->
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3"> Sign in to <span class="color-secondary">Admin</span> </sdHeading>
        <a-form-item name="username" label="Username or Email Address">
          <a-input type="text" v-model:value="formState.userName" />
        </a-form-item>
        <a-form-item name="password" initialValue="123456" label="Password">
          <a-input type="password" v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <div class="auth-form-action">
          <!-- <a-checkbox @change="onChange">Keep me logged in</a-checkbox> -->
          <router-link class="forgot-pass-link" to="/auth/forgotPassword">Forgot password?</router-link>
        </div>
        <a-form-item>
          <sdButton class="btn-signin" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

const SignIn = {
  name: 'SignIn',
  components: { AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const router = useRouter();
    const loginData = computed(() => state.auth.login);

    const handleSubmit = async () => {
      dispatch('login', formState);
    };

    watchEffect(() => {
      if (loginData.value) {
        if (loginData.value != null) {
          router.push('/');
        }
      }
    });
    const formState = reactive({
      userName: '',
      password: '',
    });

    return {
      isLoading,
      handleSubmit,
      formState,
      DataService,
    };
  },
};

export default SignIn;
</script>
